import * as React from "react";
import { styled } from "@twilio-paste/core/styling-library";
import { Box, BoxStyleProps } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { Option, Select } from "@twilio-paste/core/select";
import { Table, Tr, Td, THead, Th, TBody } from "@twilio-paste/core/table";
import { Anchor } from "@twilio-paste/core/anchor";
import { Alert, Button, Heading, Separator, Stack } from "@twilio-paste/core";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { InformationIcon } from "@twilio-paste/icons/esm/InformationIcon";
import {  useParams } from "react-router-dom";



interface FancyBoxProps {
  padding: BoxStyleProps["padding"];
}
const FancyBox: React.FC<FancyBoxProps> = styled(Box)({
  width: "40%",
});

export const IndexPage: React.FC = () => {
  const { todayCallCount,pn } = useParams();

  const show = todayCallCount != 'x';

  console.log(todayCallCount, show)
const alertMsg = 'Calls from tenant received today: '+todayCallCount;
  return (
    <Box
      display="flex"
      padding="space100"
      flexDirection="column"
      rowGap="space40"
    >
          {show && <Alert onDismiss={() => alert("dismissed")} variant="neutral">
							<Text as="span">
								<b>{alertMsg} </b>
							</Text>
						</Alert> }
            <Heading as="h2" variant="heading20">
        Tenant Details
      </Heading>
      <Stack orientation="horizontal" spacing="space10">
      <InformationIcon decorative={false} title="Description of icon" color="colorTextError" size="sizeIcon80"  /> <Text as="p" fontSize="fontSize80" color="colorTextError">Vulnerable Tenant </Text>
      </Stack>
      <Box display="flex" justifyContent="space-between"></Box>
      <Box display="flex" justifyContent="space-between">

  


        <Text as="p"><b>Name:</b> John Smith</Text>
        <Text as="p"><b>Last call:</b> 14/06/2022 11:00am</Text>
      </Box>
      <Text as="p"><b>Address:</b> 123 Fake Street</Text>
      <Text as="p"><b>Council:</b> Salford</Text>
      <Text as="p"><b>Daytime Phone Number:</b> +447516 029742</Text>
      <Text as="p"><b>Evening Phone Number:</b> +447999 123456</Text>
      <Text as="p"><b>Start of tenancy:</b> 01/01/2022</Text>
      <Box
        paddingTop="space20"
        width="40%"
        flexDirection="row"
        display="flex"
        columnGap="space60"
      ></Box>
      <Separator orientation="horizontal"></Separator>

      <Heading as="h2" variant="heading20">
        Tenant Cases
      </Heading>
      <Box
        paddingTop="space20"
        width="40%"
        flexDirection="row"
        display="flex"
        columnGap="space60"
      >
      
        <Select>
          <Option value="category">Category</Option>
        </Select>

        <Select>
          <Option value="status">Status</Option>
        </Select>

        <Button variant="primary"  >
						<FilterIcon decorative={false} title="Apply filters"  />
							Apply
					</Button>
      </Box>



      <Box display="flex" paddingTop="space100">
        <Table>
          <THead>
            <Tr>
              <Th>Ref</Th>
              <Th>Description</Th>
              <Th>Category</Th>
              <Th>Status</Th>
              <Th>Tenant Review</Th>
            </Tr>
          </THead>
          <TBody>
            <Tr>
              <Td>
                <Anchor href={"/page1/"+encodeURIComponent(pn ?? '')}>#SL124364</Anchor>
              </Td>
              <Td>Top floor leak from roof</Td>
              <Td>Structural</Td>
              <Td>Open</Td>
              <Td>n/a</Td>
            </Tr>
            <Tr>
              <Td>
                <Anchor href="">#SL463124</Anchor>
              </Td>
              <Td>Damage to carpet</Td>
              <Td>Flooring</Td>
              <Td>Closed</Td>
              <Td>&#11088; &#11088; &#11088;</Td>
            </Tr>
            <Tr>
              <Td>
                <Anchor href="">#SL182354</Anchor>
              </Td>
              <Td>Radiator leaking</Td>
              <Td>Plumbing</Td>
              <Td>Closed</Td>
              <Td>&#11088;</Td>
            </Tr>
          </TBody>
        </Table>
      </Box>
    </Box>
  );
};
